/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

import Header from "./header";

library.add(fab);

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          href={`https://freelancewebdesigner.lk/`}
          hreflang="en-lk"
        />
        <link rel="alternate" href={`https://yohan.dev/`} hreflang="en" />
        <link
          rel="alternate"
          href={`https://yohan.dev/`}
          hreflang="x-default"
        />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>

      <footer className="relative mt-24">
        <div className="container font-medium py-12 flex flex-col sm:flex-row mx-auto justify-between text-gray-500 space-x-2">
          <div className="text-lg mb-4 sm:mb-0">
            © {new Date().getFullYear()} yohan.dev
          </div>
          <div className="inline-flex space-x-3 lg:space-x-4 items-center">
            <div className="flex lg:flex-1">
              <a
                href="https://www.linkedin.com/company/yohandotdev/"
                rel="nofollow noopener noreferrer"
                target="_blank"
                className="hover:text-gray-600"
              >
                <span className="sr-only">LinkedIn</span>
                <FontAwesomeIcon icon={["fab", "linkedin-in"]} size="lg" />
              </a>
            </div>
            <div className="flex lg:flex-1">
              <a
                href="https://www.instagram.com/yohandotdev"
                rel="nofollow noopener noreferrer"
                target="_blank"
                className="hover:text-gray-600"
              >
                <span className="sr-only">Instagram</span>
                <FontAwesomeIcon icon={["fab", "instagram"]} size="lg" />
              </a>
            </div>
            <div className="flex lg:flex-1">
              <a
                href="https://www.github.com/yohandalpe"
                rel="nofollow noopener noreferrer"
                target="_blank"
                className="hover:text-gray-600"
              >
                <span className="sr-only">GitHub</span>
                <FontAwesomeIcon icon={["fab", "github"]} size="lg" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
